import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import RecruitmentBox from "../components/recruitmentBox"
import Button from "../components/button"

import { FaBoxOpen } from "react-icons/fa"

import { css } from "@emotion/core"

const selItem = (index) => {
    var abs = Math.abs(index)

    if (abs === 0) return 0
    if (abs === 1) return 2
    if (abs === 2) return 1
    if (abs === 3) return 1
    if (abs === 4) return 2
    if (abs === 5) return 1
    if (abs === 6) return 2
    if (abs === 7) return 0
    if (abs > 7) return 1
}

const ProductTemplate = (props) => {
    const product = props.data.product
    // console.log(props)

    return (
        <Layout>
            <Seo title={`${product.name} en Suisse romande`} description={product.goal} />

            <div css={productHeader}>
                <div className="container" >
                    {product.thumb &&
                        <GatsbyImage image={product.thumb.childImageSharp.gatsbyImageData} />
                    }

                    <h1><span><FaBoxOpen /> Box</span> {product.name} en Suisse romande</h1>

                    <h2>Votre besoin digital</h2>
                    <p>{product.goal}</p>

                    <h2>Livrables du projet</h2>
                    <p>{product.deliverables}</p>

                    <Button url="#contact" text={`Demander une offre`} />
                </div>
            </div>

            <div css={mainDetails}>
                {product.benefits && 
                    <div className="container">
                        <div>
                            <h2>{product.name} : quels objectifs ?</h2>
                            {product.benefits.map( (benefit, index) => (
                                <div key={benefit.title}>
                                    <h3>{index+1} - {benefit.title}</h3>
                                    <p>{benefit.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                
                {product.included && 
                    <div style={{
                        backgroundColor: "#f8f5f1",
                        padding: '70px 0 50px',
                        marginTop: '50px',
                    }}>
                        <div className="container" >
                            <h2>Sont inclus dans la box {product.name}</h2>
                            {product.included.map( (item, index) => (
                                <div key={item.title}>
                                    <h3>{index+1} - {item.title}</h3>
                                    <p>{item.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>

            <div className="container">
                <div className="form-wrapper" id="contact">
                    <h2><span className="stabilo">Demander une offre</span><br/>{product.name} en Suisse romande</h2>
                    <form name={`demande-produit-${product.yamlId}`} data-netlify="true" method="post" netlify-honeypot="bot-field" action="/merci">
                        <div className="form-container">
                            <label htmlFor="first-name" className="field half-width half-width-left">
                                <span>Prénom</span>
                                <input id="first-name" type="text" name="first-name" aria-label="first-name" placeholder="Emilie" required />
                            </label>

                            <label htmlFor="last-name" className="field half-width half-width-right">
                                <span>Nom</span>
                                <input id="last-name" type="text" name="last-name" aria-label="last-name" placeholder="Duvalais" required />
                            </label>

                            <label htmlFor="email" className="field half-width half-width-left">
                                <span>Email</span>
                                <input id="email" type="email" name="email" aria-label="email" placeholder="mon.email@duvalais.ch" required />
                            </label>

                            <label htmlFor="company" className="field half-width half-width-right">
                                <span>Entreprise</span>
                                <input id="company" type="text" name="company" aria-label="company" placeholder="MonEntreprise Sàrl" required />
                            </label>

                            <label className="field half-width half-width-left" htmlFor="localisation">
                                <span>Votre adresse principale d'entreprise (en Suisse romande)</span>
                                <input id="localisation" type="text" name="localisation" aria-label="localisation" placeholder="Place Riponne, 1004 Lausanne, VD" required/>
                            </label>

                            <label htmlFor="phone" className="field half-width half-width-right">
                                <span>Téléphone</span>
                                <input id="phone" type="phone" name="phone" aria-label="phone" placeholder="Duvalais" required />
                            </label>
                
                            <label className="field full-width" htmlFor="website">
                                <span>Lien de votre site web</span>
                                <input id="website" type="url" name="website" aria-label="website" placeholder="https://mon-entreprise.ch" required/>
                            </label>
                
                            <label className="field half-width half-width-left" htmlFor="max-budget">
                            <span>Quel est votre budget max. ?</span>
                            <input id="max-budget" type="number" name="max-budget" aria-label="max-budget" placeholder="X.- CHF" min="100" max="100000" required/>
                            </label>
                
                            <label className="field full-width" htmlFor="message">
                                <span>Si nécessaire, vous pouvez nous donner plus de détails ici :</span>
                                <textarea id="message" type="text" name="message" aria-label="message" placeholder="Je vous explique : j'aimerais réaliser ..." rows="4"></textarea>
                            </label>

                            <input type="hidden" name="bot-field" aria-label="bot field" />
                            <input type="hidden" name="form-name"  aria-label="form name" value={`demande-produit-${product.yamlId}`} />

                            <div>
                                <button className="send-button" type="submit" aria-label="Envoyer">Envoyer la demande</button>
                            </div>
                        </div>
                    </form>
                </div>

                <RecruitmentBox 
                    agence
                    prestation={product.name}
                />

                <RecruitmentBox 
                    freelance
                    prestation={product.name}
                />

                {product.definition && 
                    <div css={helpSection}>
                        <div style={{marginBottom: '20px', textTransform: 'uppercase', fontWeight: '800', fontSize: '12px', letterSpacing: '2px'}}
                            ><span className="stabilo">Définitions</span>
                        </div>

                        <div>
                            <h2>{product.name}, c'est quoi ?</h2>
                            <p>{product.definition}</p>
                        </div>

                        {product.approach &&
                            <div>
                                <h2>Notre approche</h2>
                                <p>{product.approach}</p>
                            </div>
                        }

                        {product.genre &&
                            <div>
                                {[
                                    {canton: "Canton de Vaud", city: "Lausanne"},
                                    {canton: "Canton de Genève", city: "Genève"},
                                    {canton: "Canton du Jura", city: "Delémont"},
                                    {canton: "Canton du Neuchâtel", city: "Neuchâtel"},
                                    {canton: "Canton de Vaud", city: "Sion"},
                                    {canton: "Canton du Fribourg", city: "Fribourg"},
                                    {canton: "Canton du Berne", city: "Berne"},
                                ].map( (place, index) => (
                                    <div key={place.city}>
                                        <h2>Faire {product.genre} à {place.city}, {place.canton}</h2>
                                        <p>{["Votre entreprise est basée", "Votre entreprise est active", "Vous êtes une entreprise ou association"][selItem(index)]} dans le {place.canton} et vous {["souhaitez réaliser", "comptez faire", "avez besoin d'organiser"][selItem(index)]} {product.genre}. {["Nous vous permettons de", "Nous allons vous permettre de", "Grâce à Digital Box, vous pouvez facilement"][selItem(index)]} trouver {["les bons prestataires", "des entreprises de qualité", "le bon partenaire professionnel"][selItem(index)]} ou freelances {["afin de réaliser", "de façon à pouvoir réaliser", "pour vous permettre de faire"][selItem(index)]} {product.genre} dans la région de {place.city}.</p>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                }
            </div>
        </Layout>
    )
}

export default ProductTemplate

const productHeader = css`
    padding-top: 20px;
    padding-bottom: 40px;
    margin-bottom: 70px;
    background-color: #f8f5f1;

    img {
        border-radius: 10px;
    }

    h1 {
        margin-top: 40px;

        span {
            padding: 10px 20px;
            background-color: white;
            border-radius: 100px;
            font-size: 18px;
            position: relative;
            bottom: 6px;

            svg {
                margin-bottom: -4px;
            }
        }

    }
`

const mainDetails = css`
    h2 {
        margin: 30px 0 40px;
    }

    h3 {
        font-size: 22px;
        margin-bottom: 5px;
    }
`

const helpSection = css`
    margin-top: 100px;
    border-radius: 5px;
    border: 1px solid #f8f5f1;
    padding: 20px;
    
    h2 {
        font-size: 16px;
        margin-bottom: 5px;
        margin-top: 20px;
    }

    p {
        font-size: 14px;
        margin-bottom: 0;
    }
`

export const productTemplateQuery = graphql`
    query productTemplateQuery ($id: String!) {
        product: productsYaml(id: {eq: $id}) {
            id
            yamlId
            name
            genre
            goal
            deliverables
            thumb {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        quality: 80
                    )
                }
            }
            benefits {
                title
                desc
            }
            included {
                title
                desc
            }
            definition
            approach
        }
    }
`
  