import React from 'react'
import { Link } from 'gatsby'

import { css } from "@emotion/core"
import { colors } from "../styles/constants"

const Button = (props) => {
    const {
        url,
        text, 
        alt,
        alt2,
        alt3,
        alt4,
    } = props

    function chooseStyle() {
        if ( alt ) {
            return [btn, alternate]
        } else if ( alt2 ) {
            return [btn, alternate2]
        } else if ( alt3 ) {
            return [btn, alternate3]
        } else if ( alt4 ) {
            return [btn, alternate4]
        } else {
            return btn
        }
    }

    return (
        <Link 
            to={url}
            css={chooseStyle}
        >
            <button>
                {text}
            </button>
        </Link>
    )
}

export default Button

const btn = css`
    font-family: 'Courier Prime', monospace;
    font-weight: 700;
    color: ${colors.black};
    font-size: 15px;
    transition: background-color .4s ease-in-out;    
    text-decoration: none;

    button {
        cursor: pointer;
        border: none;
        padding: 10px 20px; 
        border-radius: 50px;
        background-color: ${colors.accent1};
        box-shadow: 0 4px 0 ${colors.accent1_pale};

        :focus {
            outline: none;
        }

        :hover {
            background-color: ${colors.accent1_vivid};
        }
    }
`

const alternate =  css`
    button {
        background-color: ${colors.accent2};
        box-shadow: 0 4px 0 ${colors.accent2_pale};
        :hover { background-color: ${colors.accent2_vivid} }
    }
`
const alternate2 =  css`
    button {
        background-color: ${colors.accent3};
        box-shadow: 0 4px 0 ${colors.accent3_pale};
        :hover { background-color: ${colors.accent3_vivid} }
    }
`
const alternate3 =  css`
    button {
        background-color: ${colors.accent4};
        box-shadow: 0 4px 0 ${colors.accent4_pale};
        :hover { background-color: ${colors.accent4_vivid} }
    }
`
const alternate4 =  css`
    button {
        background-color: ${colors.accent5};
        box-shadow: 0 4px 0 ${colors.accent5_pale};
        :hover { background-color: ${colors.accent5_vivid} }
    }
`