import React from 'react'

import Button from './button'
import { FaLeaf } from "react-icons/fa"

import { css } from "@emotion/core"
import { colors } from '../styles/constants'

const RecruitmentBox = (props) => {
    const {
        freelance,
        agence,
        prestation,
        title,
        paragraph,
        btn_url,
        btn_text,
        climate
    } = props

    return (
        <div
            css={box}
            className={climate && "climate-proof"}
        >
            {freelance &&
                <div>
                    <h3>Vous êtes un.e freelance <span className="lowercase">{prestation}</span> en Suisse romande ?</h3>
                    <p>Vous êtes un.e freelance dans le domaine du digital en Suisse romande ? Si vos prestations incluent {prestation}, contactez-nous pour que nous vous ajoutions à notre carnet d'adresse de freelances de confiance, et obtenir des propositions de mandats.</p>
                    <Button
                        url={`/freelances`}
                        text="Rejoindre le réseau de freelances"
                        alt
                    />
                </div>
            }

            {agence &&
                <div>
                    <h3>Vous êtes une agence <span className="lowercase">{prestation}</span> en Suisse romande ?</h3>
                    <p>Vous êtes une agence  créative / digitale / multimédia en Suisse romande ? Si vos prestations incluent {prestation}, contactez-nous pour que nous vous ajoutions à notre carnet d'adresse d'agences de confiance, et obtenir des propositions de mandats.</p>
                    <Button
                        url={`/agences`}
                        text="Rejoindre le réseau d'agences"
                        alt
                    />
                </div>
            }

            {title && paragraph &&
                <div>
                    <h3>{climate && <FaLeaf />}{title}</h3>
                    <p>{paragraph}</p>
                    {btn_url && btn_text &&
                        <Button
                            url={btn_url}
                            text={btn_text}
                            alt
                        />
                    }
                </div>
            }
        </div>
    )
}

export default RecruitmentBox

const box = css`
    background-color: #fadc00;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0px 0px 20px #EBEBEB;
    margin-bottom: 20px;

    .lowercase {
        text-transform: lowercase;
    }

    &.climate-proof {
        background-color: ${colors.accent1_pale};
        border: 1px solid ${colors.accent1};
        padding-bottom: 0;

        h3 {
            font-size: 1.2rem;
            margin-bottom: 10px;
            color: ${colors.black};
        }

        p {
            font-size: 0.8rem;
            color: ${colors.black};
            opacity: .6;
            line-height: 1.4;
        }

        svg {
            margin-bottom: -2px;
            margin-right: 12px;
            color: limegreen;
        }
    }
`